import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/routes";
import store from "./store";
import axios from "axios";
import CKEditor from '@ckeditor/ckeditor5-vue';


axios.defaults.baseURL = "https://projects.dotlinkertech.com/konferensbokarnatest/public/api/"; // development
// axios.defaults.baseURL = "https://backend.konferensbokarna.se/konferensbokarna_backend/public/api/";  // production

createApp(App).use(store).use(CKEditor).use(router).mount("#app");
